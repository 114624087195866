import { WebAnalyticsContext } from 'lib/bbcommon/utils/bridebook-analytics';
import { Action } from 'lib/types';
import {
  IInteractedWithWeddingWebsiteAnalytics,
  WeddingWebsiteAnalyticsTypes,
} from './action-types';

export default function weddingWebsiteAnalytics(
  action: Action,
  bridebookAnalytics: WebAnalyticsContext,
) {
  const { type, payload } = action;
  const { track } = bridebookAnalytics.getMethods('weddingWebsites');
  switch (type) {
    case WeddingWebsiteAnalyticsTypes.NAVIGATION_CLICKED:
    case WeddingWebsiteAnalyticsTypes.SECTION_CLICKED:
    case WeddingWebsiteAnalyticsTypes.FEATURE_ONBOARDING_STARTED:
      track({
        event: type,
        ...payload,
      });
      break;
    case WeddingWebsiteAnalyticsTypes.INTERACTED_WITH_WEDDING_WEBSITE:
      track({
        event: type,
        ...(payload as IInteractedWithWeddingWebsiteAnalytics),
      });
      break;
  }
}

export * from './actions';
