import { Action } from 'lib/types';

export enum WeddingWebsiteAnalyticsTypes {
  NAVIGATION_CLICKED = 'Navigation clicked',
  SECTION_CLICKED = 'Section clicked',
  FEATURE_ONBOARDING_STARTED = 'Started feature onboarding',
  INTERACTED_WITH_WEDDING_WEBSITE = '[Analytics][Wedding Website] Interacted with wedding website',
}

export enum WeddingWebsiteAnalyticEvents {
  INTERACTED_WITH_WEDDING_WEBSITE = 'Interacted with wedding website',
}

export type WeddingWebsiteSectionType =
  | 'weddingWebsites_details'
  | 'weddingWebsites_design'
  | 'weddingWebsites_security'
  | 'weddingWebsites_rsvp'
  | 'weddingWebsites_guestlist';

export type WeddingWebsiteCTAType =
  | 'Wedding details'
  | 'Website design'
  | 'Privacy & Access'
  | 'Set up RSVP'
  | 'Guest list';

export interface WeddingWebsiteOnboardingAction extends Action {
  type: WeddingWebsiteAnalyticsTypes.FEATURE_ONBOARDING_STARTED;
  payload: {
    category: string;
    location: string;
  };
}

export interface SectionConfig {
  section: WeddingWebsiteSectionType;
  cta: WeddingWebsiteCTAType;
}

export interface IInteractedWithWeddingWebsiteAnalytics {
  event?: WeddingWebsiteAnalyticEvents;
  action: string;
  location: string;
  section: string;
  element: string;
  elementLabel?:
    | string
    | {
        font: string;
        palette: string;
      };
}
